<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-8">
            <div class="form-group">
              <h4>Titel:</h4>
              <input v-model="selectedNode.title" class="form-control form-control-lg" placeholder="Titel hinzufügen" type="text">
            </div>

            <div class="form-group">
              <h4>URL:</h4>
              <input v-model="selectedNode.url" class="form-control mb-3" placeholder="URL hinzufügen" type="url">
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <h5>Plattform:</h5>
              <select v-model="selectedNode.platform" class="form-control">
                <option v-for="(platform, index) in platforms" :key="index" :value="platform.title">{{ platform.title }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">

        <ul class="nav nav-tabs my-3">
          <li class="nav-item">
            <a :class="view === 'base' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'base'">
              Übersicht
            </a>
          </li>
          <li class="nav-item">
            <a :class="view === 'metrics' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'metrics'">
              Infos hinzufügen <Tooltip>Erstelle hier selbst Metriken und Attribute, um deine Erkenntnisse zu speichern</Tooltip>
            </a>
          </li>
          <li class="nav-item">
            <a :class="view === 'connections' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'connections'">
              Verlinkungen
            </a>
          </li>
          <li v-if="selectedNode.image" class="nav-item">
            <a :class="view === 'image' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'image'">
              Screenshots
            </a>
          </li>
          <li v-if="selectedNode.meta" class="nav-item">
            <a :class="view === 'meta' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'meta'">
              Meta-Tags
            </a>
          </li>
          <li v-if="selectedNode.keywords" class="nav-item">
            <a :class="view === 'keywords' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="view = 'keywords'">
              Keywords
            </a>
          </li>
        </ul>

        <div v-if="view === 'base'">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <h5>Beschreibung:</h5>
                <textarea v-model="selectedNode.description" class="form-control naked" placeholder="Beschreibung..." rows="4"></textarea>
              </div>
              <div class="form-group">
                <h5>Content-Typ:</h5>
                <select v-model="selectedNode.category" class="form-control">
                  <option v-for="(category, index) in categories" :key="index" :value="category">{{ category }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <h5>Themenkomplex:</h5>
                <select v-model="selectedNode.topics" class="form-control" multiple>
                  <option v-for="(topic, index) in topics" :key="index" :selected="selectedNode.topics.indexOf(topic) > 0" :value="topic">
                    {{ topic }}
                  </option>
                </select>
                <label class="mt-2">Neuer Themenkomplex:</label>
                <input v-model="newTopic" class="form-control" type="text" @change="addTopic($event)">
              </div>
              <div class="form-group">
                <h5>Content-Intent:</h5>
                <select v-model="selectedNode.intents" class="form-control" multiple>
                  <option v-for="(intent, index) in intents" :key="index" :selected="selectedNode.intents.indexOf(intent) >= 0" :value="intent">
                    {{ intent }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div v-if="view === 'metrics'">
          <NodeEditor :node="selectedNode"></NodeEditor>
        </div>

        <div v-if="view === 'connections'">
          <div class="form-group">
            <h5>Verlinkungen zu deiner Website:</h5>
            <ul class="list-group">
              <li v-for="(node, index) in connectedNodes" :key="index" class="list-group-item">
                <a :href="node.url">{{ node.title }}</a>
                <i class="fal fa-minus-circle text-danger float-right" @click="remove('nodeUrls', index)"></i>
              </li>
              <li class="list-group-item">
                <select v-model="selectedNode.nodeUrls" class="form-control" multiple>
                  <option v-for="(node, index) in nodes" :key="index" :value="node.url">{{ node.title }}</option>
                </select>
              </li>
            </ul>
          </div>
          <div class="form-group">
            <h5>Weiterführende Links:</h5>
            <ul class="list-group">
              <li v-for="(link, index) in selectedNode.links" :key="index" class="list-group-item">
                <a :href="link">{{ link }}</a>
                <i class="fal fa-minus-circle text-danger float-right" @click="remove('links', index)"></i>
              </li>
              <li class="list-group-item">
                <input v-model="selectedNode.newLink" class="form-control naked" placeholder="Neuen Link hinzufügen..." type="url" @change="addLink($event)">
              </li>
            </ul>
          </div>
        </div>

        <div v-if="view === 'image'">
          <ul class="nav nav-tabs my-3">
            <li class="nav-item">
              <a :class="imageView === 'thumb' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="imageView = 'thumb'">
                Vorschau <i v-if="selectedNode.image['thumb']" class="fad fa-check-circle text-primary"></i>
              </a>
            </li>
            <li class="nav-item">
              <a :class="imageView === 'desktop' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="imageView = 'desktop'">
                Desktop <i v-if="selectedNode.image['desktop']" class="fad fa-check-circle text-primary"></i>
              </a>
            </li>
            <li class="nav-item">
              <a :class="imageView === 'tablet' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="imageView = 'tablet'">
                Tablet <i v-if="selectedNode.image['tablet']" class="fad fa-check-circle text-primary"></i>
              </a>
            </li>
            <li class="nav-item">
              <a :class="imageView === 'mobile' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="imageView = 'mobile'">
                Mobile <i v-if="selectedNode.image['mobile']" class="fad fa-check-circle text-primary"></i>
              </a>
            </li>
          </ul>

          <div v-for="(size, index) in sizes" :key="'a' + index">
            <div v-show="size.prop === imageView && selectedNode.image[imageView]">
              <button @click="removeImage(imageView)" class="btn btn-dark btn-block mb-3">
                Entfernen <i class="fas fa-times"></i>
              </button>
              <div class="node-screen-viewer">
                <img :src="selectedNode.image[imageView]" alt="">
              </div>
            </div>
          </div>

          <div v-for="(size, index) in sizes" :key="'b' + index">
            <div v-show="size.prop === imageView && !selectedNode.image[imageView] && !size.uploading">
              <h5>Bild hinzufügen</h5>
              <FileUpload @filesSaved="filesSaved(imageView, $event)"
                          :filename="selectedNode.id + '-' + imageView"
              ></FileUpload>
            </div>
          </div>

          <div v-for="(size, index) in sizes" :key="'c' + index">
            <div v-show="size.prop === imageView && size.uploading">
              <p class="text-center my-5">
                <i class="fad fa-spinner fa-spin fa-2x"></i><br>
                Datei wird hochgeladen...
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary" @click="save">Externen Content speichern</button>
        <button v-if="$route.params.id" @click="$emit('removeContent')" class="btn btn-danger ml-3">
          Externen Content löschen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import FileUpload from "@/components/FileUpload";
import Tooltip from "@/components/Tooltip";
import NodeEditor from "@/components/NodeEditor";
export default {
  name: 'ExternalNode',
  props: {
    selectedNode: Object
  },
  components: {NodeEditor, Tooltip, FileUpload},
  data() {
    return {
      view: 'base',
      preview: 'image',
      imageView: 'thumb',
      sizes: [
        { title: 'Vorschau', prop: 'thumb', uploading: false },
        { title: 'Desktop', prop: 'desktop', uploading: false },
        { title: 'Tablet', prop: 'tablet', uploading: false },
        { title: 'Mobile', prop: 'mobile', uploading: false },
      ],
      categories: [
        "Text",
        "Photo",
        "Audio",
        "Video",
        "Interactive",
        "Data",
      ],
      intents: [
        "Information",
        "Conversion",
        "Branding",
        "Security",
        "Entertainment",
        "News",
        "Acquisition",
      ],
      newTopic: "",
      newLink: "",
    }
  },
  computed: {
    newestUpdate() {
      let updates = this.selectedNode.timeline.updates;
      return updates && updates.length ? updates[updates.length - 1] : null;
    },
    processedUrl() {
      return this.selectedNode ? this.selectedNode.url : '';
    },
    nodes() {
      return this.project.netNodes.map(node => {
        if (!node.title) {
          node.title = node.url.replace(this.project.profile.rootUrl, "");
        }
        return node;
      });
    },
    connectedNodes() {
      return this.selectedNode.nodeUrls.map(nodeUrl => {
        return this.nodes.find(n => n.url === nodeUrl) || {
          id: this.generateId(),
          title: nodeUrl,
          url: nodeUrl
        };
      });
    },
    platforms() {
      return this.$store.state.platforms;
    },
    topics() {
      return this.project.settings.topics;
    },
    tags() {
      return this.project.settings.tags;
    },
    contentPlatforms() {
      return this.project.settings.contentPlatforms;
    },
  },
  methods: {
    filesSaved(size, $event) {
      let files = JSON.parse(JSON.stringify($event));
      this.sizes.find(s => s.prop === size).uploading = true;
      this.$store.dispatch('upload/uploadFiles', { files, path: "content-img/" + this.project._id })
          .then(res => {
            this.selectedNode.image[size] = res.files[0];
            console.debug('filesSaved', this.selectedNode.image);
            this.$store.dispatch('project/updateProjectByProp', {prop: 'externalNodes', data: this.project.externalNodes})
                .then(() => {
                  this.sizes.find(s => s.prop === size).uploading = false;
                });
          });
    },
    removeImage(size) {
      this.selectedNode.image[size] = "";
      this.$forceUpdate();
    },
    remove(prop, index) {
      this.selectedNode[prop].splice(index, 1);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'externalNodes', data: this.project.externalNodes});
    },
    addLink($event) {
      this.selectedNode.links.push($event.target.value);
      $event.target.value = "";
      this.$store.dispatch('project/updateProjectByProp', {prop: 'externalNodes', data: this.project.externalNodes});
    },
    addUpdate() {
      this.selectedNode.updates.push(JSON.parse(JSON.stringify(this.newUpdate)));
      this.newUpdate = {update: "", updatedAt: new Date()}
      this.$store.dispatch('project/updateProjectByProp', {prop: 'externalNodes', data: this.project.externalNodes});
    },
    addTopic($event) {
      if ($event.target.value === "") return;
      this.project.settings.topics.push($event.target.value);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'settings', data: this.$store.getters.getProject.settings})
      this.selectedNode.topics.push($event.target.value);
      $event.target.value = "";
    },
    save() {
      if (this.selectedNode.url) {
        let nodeExists = (this.project.netNodes.find(n => n.url === this.selectedNode.url) ||
            this.project.externalNodes.find(n => n.url === this.selectedNode.url));

        if (nodeExists && nodeExists.id !== this.selectedNode.id) {
          console.log(this.findNode(this.selectedNode.url));
          alert("Diese URL existiert schon. Wähle eine andere oder bearbeite die existierende URL.");
        } else {
          this.$emit('save');
        }
      } else {
        alert("Du musst eine URL hinzufügen, um den Inhalt zu speichern. Du kannst auch einen Bezeichner deiner Wahl nutzen.");
      }
    },
  },
  beforeMount() {
    if (!this.selectedNode.image) {
      this.selectedNode.image = {};
    }
  }
}
</script>

<style lang="scss">
.preview-wrapper {
  width: 100%;

  .preview {
    margin: 0 auto;
  }

  iframe.preview {
    width: 100%;
    height: 100vh;
  }
}

.node-screen-viewer {
  height: 80vh;
  overflow-y: scroll;
  text-align: center;

  img {
    width: auto;
    max-width: 100%;
    margin: 0 auto;
  }
}
</style>


<!--

      newAd: {
        type: "",
        title: "",
        text: "",
        cost: 0.00,
        intent: ""
      },
      newUpdate: {update: "", updatedAt: new Date()}
      states: [
        "Ideation",
        "Conception",
        "Production",
        "Testing",
        "Live"
      ],
      adTypes: [
        "Google Ad",
        "Facebook Ad",
        "Instagram Ad",
        "YouTube Ad",
        "Pinterest Ad",
        "Twitter Ad",
        "Amazon Ad",
        "Ebay Ad",
      ],
              <div class="form-group">
                <h5>Content-Status:</h5>
                <select v-model="selectedNode.state" class="form-control">
                  <option v-for="(state, index) in states" :key="index" :value="state">{{ state }}</option>
                </select>
              </div>

            <div class="form-group">
              <h5>Eingestellt am:</h5>
              <ul class="list-group">
                <li class="list-group-item">
                  <input v-model="selectedNode.timeline.createdAt" class="form-control" type="date">
                </li>
                <li v-for="(update, index) in selectedNode.timeline.updates" :key="index" class="list-group-item">
                  {{ update.updatedAt }}
                  <i class="fal fa-minus-circle text-danger float-right" @click="remove('timeline.updates', index)"></i>
                </li>
                <li class="list-group-item">
                  <h5>Update hinzufügen</h5>
                  <label>Datum:</label>
                  <input v-model="newUpdate.updatedAt" class="form-control" type="date">
                  <label class="mt-2">Update:</label>
                  <input v-model="newUpdate.update" class="form-control" placeholder="Was wurde geändert?" type="text">
                  <button class="btn btn-sm btn-block btn-light mt-2" @click="addUpdate">Update hinzufügen</button>
                </li>
              </ul>
            </div>
            -->


<!--<div class="row">
  <div class="col-sm-4">


  </div>
  <div class="col-sm-8">
    <div class="form-group">
    </div>

    <div class="form-group">
    </div>

    <h5>Vorschau:</h5>

    <ul class="nav nav-tabs mb-3">
      <li class="nav-item">
        <a :class="preview === 'image' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="preview = 'image'">
          Bilderupload
        </a>
      </li>
      <li class="nav-item">
        <a :class="preview === 'embed' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="preview = 'embed'">
          Embed-Code
        </a>
      </li>
      <li class="nav-item">
        <a :class="preview === 'iframe' ? 'active' : ''" class="nav-link" href="javascript:void(0)" @click="preview = 'iframe'">
          Website-Vorschau
        </a>
      </li>
    </ul>

    <div v-if="preview === 'embed'" class="preview-wrapper">
      <h5>Embed-Code (überschreibt Link-Vorschau):</h5>
      <textarea v-model="selectedNode.embedCode" placeholder="Hier Embed-Code einfügen" class="form-control mb-3"></textarea>
      <div class="preview" v-html="selectedNode.embedCode"></div>
    </div>
    <div v-else-if="preview === 'iframe'" class="preview-wrapper">
      <h5>Web-Vorschau:</h5>
      <p class="small text-muted">Viele Websites verhindern, dass der Inhalt in einem iFrame angezeigt wird.</p>
      <iframe :src="processedUrl" class="preview" frameborder="0"></iframe>
    </div>
  </div>
</div>-->