<template>
  <div id="contents-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link class="btn btn-secondary mb-5 mr-3" to="/contents">Zurück zur Übersicht</router-link>
    <button @click="save" class="btn btn-primary mb-5 mr-3">Externen Content speichern</button>
    <button v-if="$route.params.id" @click="removeContent" class="btn btn-danger mb-5">Externen Content löschen</button>

    <!--<p>Todo:</p>
    <ul>
      <li>Build Google Analytics and Google Ads integration</li>
      <li>Build Charts</li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/personas">Personas</router-link></li>
      <li>Connect to <router-link to="/keywords">Keyword Planner</router-link></li>
    </ul>-->

    <ExternalNode v-if="newExternalNode"
                  :selected-node="newExternalNode"
                  mode="change"
                  @save="save"
                  @removeContent="removeContent"></ExternalNode>

  </div>
</template>

<script>
// @ is an alias to /src

import ExternalNode from "@/components/ExternalNode";

export default {
  name: 'ContentsEdit',
  components: {ExternalNode},
  data() {
    return {
      newExternalNode: {
        id: this.generateId(),
        title: "",
        description: "",
        url: "",
        embedCode: "",
        image: {
          thumb: "",
          desktop: "",
          tablet: "",
          mobile: "",
        },
        nodeUrls: [],
        state: "Ideation",
        category: "Text",
        timeline: {
          createdAt: new Date(),
          updates: [
            {update: "", updatedAt: new Date()}
          ],
          deletedAt: null
        },
        topics: [],
        tags: [],
        platform: "Website",
        links: [],
        ads: [],
        intents: [],
        metrics: {},
        attributes: {}
      },
    }
  },
  computed: {},
  methods: {
    save() {
      if (this.$route.params.id) {
        let index = this.project.externalNodes.findIndex(p => p.id === this.$route.params.id);
        this.project.externalNodes.splice(index, 1, this.newExternalNode);
      } else {
        this.project.externalNodes.push(this.newExternalNode);
      }
      this.$store.dispatch('project/updateProjectByProp', {prop: 'externalNodes', data: this.project.externalNodes})
          .then(() => {
            this.$router.push("/contents");
          });
    },
    removeContent() {
      if (confirm("Diesen Eintrag wirklich löschen?")) {
        let index = this.project.externalNodes.findIndex(p => p.id === this.$route.params.id);
        this.project.externalNodes.splice(index, 1);
        this.$store.dispatch('project/updateProjectByProp', {prop: 'externalNodes', data: this.project.externalNodes})
            .then(() => {
              this.$router.push("/contents");
            });
      }
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.newExternalNode = Object.assign({ metrics: {}, attributes: {} }, this.project.externalNodes.find(p => p.id === this.$route.params.id));
    }
  },
}
</script>

<style lang="scss" scoped>
.chart {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 110%;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>